<template>
  <b-row>

    <!-- Small -->
     

    <!-- Large -->
    <b-col cols="12">
      <b-form-group>
        <v-select  v-if="categories!=null"
          v-model="selected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          :options="categories" 
          
          class="select-size-lg"
          multiple
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import useUsersList from '../courseCategoryStoreModule'
import axios from '@axios'
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  created () {
    this.fetchCategories()
  
  },
  props :{
    initialCategories:[]
  },


  methods:{
     updateSelections(a) {
    
    //console.log(a)
  },
         fetchCategories( ) {
      //console.log("fetchCategories fetch");
      return new Promise((resolve, reject) => {
        axios
          .get('/lms/admin/course/category' )
          .then((response)=> { 
           
         
           var data=  response.data;
           var responseData=JSON.parse(  JSON.stringify( data));
          //console.log(responseData.data);
              this.categories=responseData.data;

          

         

            }
            )
          .catch(error => reject(error))
      })
    },
  },
  watch: {
  selected: function (newVal,coldVal) {
    
//console.log(this.initialCategories);
//console.log(this.selected);

     this.$emit("selectionChanged", newVal);

  }
},
  data() {
    return {
      categories:null,
      selected: this.initialCategories,
     }
  }
   

}
</script>