<template>
  <b-card>
    <!-- form -->
    <h6 class="section-label mb-3  mt-1">Certification</h6>
    <b-form>
    
      <b-row>
        <!--/ birth date -->
        <b-col md="12">
            <b-card-text  class="text-info " >
            Will The Course be certified by the instructor after the course is completed?
          </b-card-text> 
          <div class="demo-inline-spacing mb-1">
                  <b-form-radio v-model="isCertified"   name="cert-radios" value="0">
              None Certificate
            </b-form-radio>
            <b-form-radio v-model="isCertified"  name="cert-radios" value="1">
              Yes Certified
            </b-form-radio>
        
          </div>
        </b-col>
      </b-row>
      <b-row v-if="isCertified == 1">
        <!--/ birth date -->
        
        <b-col md="12">
          <b-form-group label-for="countryList" label="Select Certificate">
            <v-select
              id="countryList"
              v-model="localOptions.certificate"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="certificatesOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <h6 class="section-label mt-4 mb-4">Qualification of Certificate</h6>

         <b-row>
        <!--/ birth date -->
        <b-col md="12" class="text-info ">
            <b-card-text >
            Will the certificate be issued  after the exams are completed?
          </b-card-text> 
          <div class="demo-inline-spacing mb-1">
                  <b-form-radio v-model="isExamDepended"   name="exam-radios" value="0">
              The certificate will be issued after the course is completed
            </b-form-radio>
            <b-form-radio v-model="isExamDepended"  name="exam-radios" value="1">
            The certificate will be issued after the exams are completed
            </b-form-radio>
        
          </div>
        </b-col>
      </b-row>
      <b-row v-if="isExamDepended == 1">
        <!--/ birth date -->
        
        <b-col md="12">
          <b-form-group label-for="countryList" label="Select exam for certification">
            <v-select
              id="countryList"
              v-model="localOptions.certificateExam"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="examOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BFormRadio,
  BCardText,
      BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";

// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
    BCardText,
    BFormRadio,
        BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      certificatesOptions: [ "Certificate of Completion" , "Certificate of Participation" , "Certificate of Attendance" ],
      examOptions: [ "Exam 1" , "Exam 2" , "Exam 3" ],
    
    countryOption: ["USA", "India", "Canada"],
      localOptions: JSON.parse(JSON.stringify(this.informationData)),
      isCertified: "0",
      isExamDepended: "0",
      hasCertainStartDate: "0",
        hasCertainEndDate: "0",
      allowPublicAccess: "0",
      clevePhone: {
        phone: true,
        phoneRegionCode: "US",
      },
    };
  },
  methods: {
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
